<template>
  <div>
    <h3>My Messages</h3>
    <main class="content">
      <div class="container p-0">
        <div class="card">
          <div class="row g-0">
            <div class="col-12 col-lg-5 col-xl-3 border-right">
              <div class="px-4 d-none d-md-block">
                <div class="text-end mt-3">
                  <a href="#" @click.prevent="create_new_message = true"
                    >New Message</a
                  >
                </div>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <input
                      type="text"
                      class="form-control my-3"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>

              <router-link
                :to="{ query: { id: user.id }}"
                :class="`list-group-item list-group-item-action border-0 ${user.read ? '' : 'chat-unread'}`"
                v-for="(user, i) in users"
                :key="`user-${i}`"
              >
                <div class="d-flex align-items-start">
                  <img
                    :src="user.avatar"
                    class="rounded-circle mr-1"
                    :alt="user.name"
                    width="40"
                    height="40"
                  />
                  <div class="flex-grow-1 ml-3">
                    {{ user.name }}
                    <div class="small">
                      {{ user.message }}
                    </div>
                  </div>
                </div>
              </router-link>

              <hr class="d-block d-lg-none mt-1 mb-0" />
            </div>
            <div class="col-12 col-lg-7 col-xl-9">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </main>
    <modal :show="create_new_message" @close="create_new_message = false">
      <teplate slot="header">Send New Message</teplate>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Select New User</label>
          <v-select
            :options="all_users"
            label="name"
            placeholder="Select User"
            class="form-control"
            v-model="selected_user"
          />
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea class="form-control" v-model="new_message" rows="4" placeholder="Message" required></textarea>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Send</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
      loading: true,
      create_new_message: false,
      all_users: [],
      selected_user: null,
      new_message: null
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/messages").then((response) => {
        this.users = response.data.users;
        this.all_users = response.data.all_users;
        this.loading = false;
      });
    },

    submit() {
      let data = {
        to: this.selected_user.id,
        content: this.new_message
      }

      this.$axios.post('/api/v1/messages', data).then(() => {
        this.$router.push({ query: { id: this.selected_user.id }})
        this.create_new_message = false
        this.fetch()
      })
    }
  },
};
</script>

<style>
.border-right {
  border-right: solid 1px #eee;
}

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-unread {
  font-weight: bold;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
  width: 80%;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
</style>
